import request from '../utils/request';
import UrlUtil from '../utils/url-config';

const API = UrlUtil.getAPI();
//商品模块接口
export const ShopFetch = {
    //获取商品首页广告图
    searchBannerList: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/searchBannerList`, params, success);
    },
    //记录当前广告的点击次数
    updateBannerClick: (params, success) => {
        return request('POST', `${API}/v1/shop/home/noAuth/changeClickNum/${params}`, params, success)
    },
    //获取商品首页广告图
    searchIndexGoodsList: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/searchIndexGoodsList`, params, success)
    },
    //分页获取首页公告列表
    searchNoticeList: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/searchNoticeList`, params, success)
    },
    //获取公告详情
    getNoticeById: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/getNoticeById/${params}`, params, success)
    },
    //获取商品详情
    getGoodsById: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getGoodsById/` + params, params, success)
    },
    //获取商品详情
    getGoodsByIdAndClientIdForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getGoodsByIdAndClientIdForShop`, params, success)
    },
    // 根据产品条形码获取客户产品信息
    getGoodsByBarcodeAndClientIdForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/getGoodsByBarcodeAndClientIdForShop`, params, success)
    },
    //商城商品分页查询
    searchListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchListForShop`, params, success)
    },
    //web端查询收藏商品
    searchFavoriteGoodsForWeb: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchFavoriteGoodsForWeb`, params, success)
    },
    //web端收藏商品分类筛选
    searchTypeByFavorite: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchTypeByFavorite`, params, success)
    },
    //web端查询常购商品清单
    searchClientGoodsForWeb: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchClientGoodsForWeb`, params, success)
    },
    //web端常购商品分类筛选
    searchTypeByClientGoods: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchTypeByClientGoods`, params, success)
    },
    //获取指定公司的商品分类树
    getUsePropertyTree: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getUsePropertyTree`, params, success)
    },
    //商城商品分页查询
    searchGoods: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchGoods`, params, success)
    },
    //查询商品被收藏数量
    countFavorites: (params, success) => {
        return request('GET', `${API}/v1/shop/goodsFavorite/noAuth/countFavorites/${params}`, params, success)
    },
    //商城商品分页查询
    checkPhone: (params, success) => {
        return request('GET', `${API}/api/v1/account/checkPhone`, params, success)
    },
    addGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/addGoodsFavorite`, params, success)
    },
    deleteGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/deleteGoodsFavorite`, params, success)
    },
    batchDeleteGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/batchDeleteGoodsFavorite`, params, success)
    },
    //列出企业所有商品风格
    listStyleByCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listStyleByCompany`, params, success)
    },
    //列出企业所有商品品牌
    listBrandByCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listBrandByCompany`, params, success)
    },
    //列出企业所有商品分类
    listTypeByCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getTypeTree`, params, success)
    },
    //列出企业所有商品标签
    searchTagList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listTagByCompany`, params, success)
    },
    //获取商品设置信息
    getGoodsSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/goodsSetting/getByCompanyId/${params}`, params, success)
    },
    //获取销售设置信息
    getSaleSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getSaleSetting/${params}`, params, success)
    },
    //获取销售设置信息
    getShopSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getShopSetting/${params}`, params, success)
    },
    //获取商城开放注册应用设置
    getShopRegisterSetting: (params, success) => {
        return request('GET', `${API}/v1/shopRegisterSetting/noAuth/getByCompany`, params, success)
    },
    //获取仓库设置
    getHouseSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getHouseSetting/${params}`, params, success)
    },
    //获取企业信息
    getCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/getCompany/${params}`, params, success)
    },
    // 获取客户分类列表
    findList: (params, success) => {
        return request('GET', `${API}/v1/client/clientType/findList`, params, success)
    },
    // 获取首页信息
    getPageDataByWeb: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/getPageDataByWeb`, params, success)
    },
    // 获取店铺装修商品
    searchListForDecorate: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/searchListForDecorate`, params, success)
    },
    // 获取店铺装修商品
    countDecorateVisitor: (params, success) => {
        return request('POST', `${API}/v1/decorate/wpShopTemplate/noAuth/countVisitor/${params}`, params, success)
    },
    // 获取店铺装修促销
    listForGoodsPromotionActivityIds: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/listForGoodsPromotionActivityIds`, params, success)
    },
    //查询指定商品当前应用的促销
    goodsPromotionByGoods: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/listByGoods/${params}`, params, success)
    },
    //查询促销列表
    searchListActivityForShop: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/noAuth/searchListActivityForShop`, params, success)
    },
    //获取运费计算设置
    getShipFeeSettingByLogin: (params, success) => {
        return request('GET', `${API}/v1/setting/shipFeeSetting/getByLoginUser`, params, success)
    },

    //获取预存款明细
    searchPreBalanceListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/order/searchPreBalanceListForShop`, params, success)
    },
    //记录浏览商品足迹
    saveGoodsViewLog: (params, success) => {
        return request('POST', `${API}/v1/goods/goodsViewLog/save`, params, success)
    },
    //获取浏览商品足迹
    searchViewLogListForShop: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsViewLog/searchViewLogListForShop`, params, success)
    },
    //刪除足迹
    deleteViewLogListByIds: (params, success) => {
        return request('DELETE', `${API}/v1/goods/goodsViewLog/deleteByIds/${params}`, params, success)
    },
    //获取推荐商品
    searchRecommendedGoodsListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchRecommendedGoodsListForShop`, params, success)
    },
    //获取商品套餐列表
    searchGoodsPackageListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchGoodsPackageListForShop`, params, success)
    },
    // 根据分类查询关联品牌
    searchBrandList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchBrandList`, params, success)
    },
    // 根据分类查询商品热词
    searchGoodsCustomKeywordList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchGoodsCustomKeywordList`, params, success)
    },
    // 查询指定分支机构
    getSubsidiaryById: (params, success) => {
        return request('GET', `${API}/v1/shop/subsidiary/getById/${params}`, params, success)
    },
    // 查询指定分支机构
    getShopHomeClientCredit: (params, success) => {
        return request('GET', `${API}/v1/shop/home/getShopHomeClientCredit`, params, success)
    },
    // 云通信账号
    getByFkIdAndType: (params, success) => {
        return request('GET', `${API}/v1/base/neteaseImAccount/getByFkIdAndType`, params, success)
    },
    // 获取专享商品
    getSearchProductList: (params, success) => {
        return request('GET', `${API}/v1/shop/clientPaidMember/searchProductList`, params, success)
    },
    //查询客户是否申请注销
    cancelInfoByClientId: (params, success) => {
        return request('GET', `${API}/v1/shopRegisterSetting/cancelInfoByClientId/${params}`, params, success)
    },
    //新增注销记录
    saveCancel: (params, success) => {
        return request('BODY', `${API}/v1/shopRegisterSetting/saveCancel`, params, success)
    },
};
//购物车模块接口
export const CartFetch = {
    // 保存/修改商城购物车列表
    batchModify: (params, success) => {
        return request('BODY', `${API}/v1/shop/cart/batchModify`, params, success)
    },
    searchList: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/searchList`, params, success)
    },
    //保存/修改商城购物车列表
    saveCart: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/updateForShop`, params, success)
    },
    //webapp再来一单添加商品合并到购物车
    mergeCartForWeb: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/mergeCartForWeb`, params, success)
    },
    //修改商城购物车列表
    updateCart: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/updateForWeb`, params, success)
    },
    //购物车查询
    searchListForShopCart: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/searchListForShopCart`, params, success)
    },
    //修改商城购物车数量
    modifyCartNum: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/modifyCartNum`, params, success)
    },
    //删除商城购物车
    batchDelete: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/batchDelete`, params, success)
    },
    //列出企业配置的配送方式
    listShipWay: (params, success) => {
        return request('GET', `${API}/v1/shop/order/noAuth/listShipWay`, params, success)
    },
    //获取送货地址--代客下单
    clientAddress: (params, success) => {
        return request('GET', `${API}/v1/sale/valetSale/listByClientId/${params}`, params, success)
    },
    //获取发票类型
    getSaleSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getSaleSetting/` + params, params, success)
    },
    //保存订单
    save: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/save`, params, success)
    },
    //保存预售订单
    presaleSave: (params, success) => {
        return request('POST', `${API}/v1/shop/order/presaleSave`, params, success)
    },
    //获取商品优惠信息
    getPromotionByGoodsId: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getPromotionByGoodsId`, params, success)
    },
    //根据规格ids串获取商品优惠信息
    getPromotionByProductIds: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getPromotionByProductIds`, params, success)
    },
    //获取商品优惠信息
    getPromotionByProductId: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getPromotionByProductId`, params, success)
    },
    //获取商品优惠信息
    getActivityById: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getActivityById/${params}`, null, success)
    },
    //获取订单优惠信息
    getPromotionByCompanyId: (params, success) => {
        return request('GET', `${API}/v1/sale/orderPromotion/getPromotionByCompanyId`, params, success)
    },
    //获取购物车SKU总数量
    sumNumber: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/sumNumber`, params, success)
    },
    //清除购物车下架商品
    cleanDownawayGoods: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/cleanDownawayGoods`, params, success)
    },
    //批量添加商城商品收藏
    batchAddGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/batchAddGoodsFavorite`, params, success)
    },
    //合并订单
    combinedPaySave: (params, success) => {
        return request('BODY', `${API}/v1/finance/combinedPay/save`, params, success)
    },
    //合并订单
    combinedPayGetById: (params, success) => {
        return request('GET', `${API}/v1/finance/combinedPay/getById/${params}`, params, success)
    },
    //获取优惠券活动
    getCouponActivityById: (params, success) => {
        return request('GET', `${API}/v1/shop/coupon/getCouponActivityById/${params}`, params, success)
    },
    //获取商品预售信息
    getpresalePromotionByGoodsId: (params, success) => {
        return request('GET', `${API}/v1/sale/presalePromotion/getPromotionByGoodsId`, params, success)
    },
    //获取商品预售信息
    getPresalePromotionByProductIds: (params, success) => {
        return request('GET', `${API}/v1/sale/presalePromotion/getPromotionByProductIds`, params, success)
    },
    //获取预售活动信息
    getPresalePromotionById: (params, success) => {
        return request('GET', `${API}/v1/sale/presalePromotion/getActivityById/${params}`, params, success)
    },
    //购物车商品缓存添加
    batchSaveCartCache: (params, success) => {
        return request('POST', `${API}/v1/goods/goodsToCartCache/batchSave`, params, success)
    },
    //购物车商品缓存
    deleteByEditCartCache: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsToCartCache/deleteByEdit`, params, success)
    },
    //购物车商品缓存
    cleanByclientIdCartCache: (params, success) => {
        return request('DELETE', `${API}/v1/goods/goodsToCartCache/cleanByclientId/${params}`, params, success)
    },
    //计算购物车配送相关
    batchCalDistanceWithHouse: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/batchCalDistanceWithHouse`, params, success)
    },
    //计算购物车运费计算
    calShipFee: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/calShipFee`, params, success)
    },
};
//商城订单
export const OrderFetch = {
    //保存/修改商城购物车列表
    getById: (params, success) => {
        return request('GET', `${API}/v1/shop/order/getById/` + params, params, success)
    },
    //无需鉴权获取订单信息
    getByIdNoAuth: (params, success) => {
        return request('GET', `${API}/v1/shop/order/noAuth/getById/${params}`, params, success)
    },
    // 获取订单详情
    getSaleOrderById: (params, success) => {
        return request('GET', `${API}/v1/sale/saleOrder/getById/${params}`, params, success)
    },
    //订单支付(线下或预付款)
    orderPay: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/orderPayByOfflineOrPre`, params, success)
    },
    //订单支付(线下或预付款)
    getPreBalance: (params, success) => {
        return request('GET', `${API}/v1/shop/order/getPreBalance`, params, success)
    },
    //
    searchListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/order/searchListForShop`, params, success)
    },
    //取消订单
    orderCancel: (params, success) => {
        return request('POST', `${API}/v1/shop/order/cancel`, params, success)
    },
    //删除订单
    deleteOrderById: (params, success) => {
        return request('DELETE', `${API}/v1/shop/order/deleteById/${params}`, params, success)
    },
    //删除订单
    markNotViewForShop: (params, success) => {
        return request('DELETE', `${API}/v1/shop/order/markNotViewForShop/${params}`, params, success)
    },
    //确认收货
    receiveBySaleOrder: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/receiveBySaleOrder`, params, success)
    },
    //确认收货
    receive: (params, success) => {
        return request('POST', `${API}/v1/shop/order/receive`, params, success)
    },
    // 根据快递单号获取物流信息
    getLogisticInfo: (params, success) => {
        return request('GET', `${API}/v1/common/helper/getLogisticInfo`, params, success)
    },
    // 获取企业线下收款账号
    listOfflineAccount: (params, success) => {
        return request('GET', `${API}/v1/shop/order/listOfflineAccount`, params, success)
    },
    // 获取企业线下收款账号
    listAccount: (params, success) => {
        return request('GET', `${API}/v1/shop/order/listAccount`, params, success)
    },
    // 获取企业线上收款账号
    listOnlineAccount: (params, success) => {
        return request('GET', `${API}/v1/shop/order/listOnlineAccount`, params, success)
    },
    orderPayByOfflineOrPre: (params, success) => {
        return request('POST', `${API}/v1/shop/order/orderPayByOfflineOrPre`, params, success);
    },
    // 分页获取商城订单消息列表
    searchOrderMessage: (params, success) => {
        return request('GET', `${API}/v1/shop/orderMessage/searchListByLogin`, params, success)
    },
    // 标记订单消息为已读
    markRead: (params, success) => {
        return request('POST', `${API}/v1/shop/orderMessage/markRead/${params}`, null, success);
    },
    // 标记订单消息为全部已读
    markAllRead: (params, success) => {
        return request('POST', `${API}/v1/shop/orderMessage/markAllRead`, params, success);
    },
    //获取收款单核销记录
    findSaleOrderIncomeList: (params, success) => {
        return request('GET', `${API}/v1/finance/income/findSaleOrderIncomeList/${params}`, params, success)
    },
    //修改销售单商品备注
    modifyItemRemark: (params, success) => {
        return request('POST', `${API}/v1/shop/order/modifyItemRemark`, params, success)
    },
    //客户订货统计（最近两年）
    clientSaleReport: (params, success) => {
        return request('GET', `${API}/v1/shop/order/clientSaleReport`, params, success)
    },
    // 规格销售报表-按规格统计列表
    productSaleReportSearchList: (params, success) => {
        return request('GET', `${API}/v1/shop/order/productSaleReportSearchList`, params, success)
    },
    // 分类销售报表-按分类统计列表
    goodsTypeSaleReportSearchList: (params, success) => {
        return request('GET', `${API}/v1/shop/order/goodsTypeSaleReportSearchList`, params, success)
    },
    // 添加日志
    saveOrderLog: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/saveOrderLog`, params, success)
    },
    //
    getOrderMoneyRestrictDto: (params, success) => {
        return request('GET', `${API}/v1/sale/saleOrder/getOrderMoneyRestrictDto/${params}`, params, success)
    },
    //
    getBySaleOrder: (params, success) => {
        return request('GET', `${API}/v1/subsidiary/subsidiaryOfflineIncomeRecord/getBySaleOrder/${params}`, params, success)
    },

    //查询指定订单的分支机构线下收款记录
    listBySaleOrder: (params, success) => {
        return request('GET', `${API}/v1/subsidiary/subsidiaryOfflineIncomeRecord/listBySaleOrder`, params, success)
    },

};
//系统模块
export const SystemFetch = {
    //订货端获取企业设置
    getShopCompanySetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getShopCompanySetting/${params}`, params, success)
    },
    //管理端-第一步点击就记录体验信息-体验
    experienceRegister: (params, success) => {
        return request('POST', `${API}/v1/account/experienceRegister`, params, success)
    },
    //管理端-体验
    experience: (params, success) => {
        return request('POST', `${API}/v1/account/getExperienceUser`, {...params, type_: 1}, success)
    },
    //订货端-体验
    shopExperience: (params, success) => {
        return request('POST', `${API}/v1/account/getExperienceUser`, {...params, type_: 2}, success)
    },
    //登陆
    login: (params, success) => {
        return request('POST', `${API}/v1/account/login`, params, success)
    },
    //混合登陆（兼容账号跟手机号去登陆）
    loginMixed: (params, success) => {
        return request('POST', `${API}/v1/account/mixedLogin`, params, success)
    },
    //登陆
    logout: (params, success) => {
        return request('POST', `${API}/v1/shop/account/noAuth/logout`, params, success)
    },
    //验证验证码
    checkRegistCode: (params, success) => {
        return request('POST', `${API}/v1/account/checkRegistCode`, params, success)
    },
    //创建企业
    createCompany: (params, success) => {
        return request('POST', `${API}/v1/account/createCompany`, params, success)
    },
    //获取重置密码验证码
    resetCode: (params, success) => {
        return request('GET', `${API}/v1/shop/account/noAuth/resetCode`, params, success)
    },
    //发送注册验证码
    registCode: (params, success) => {
        return request('GET', `${API}/v1/account/registCode`, params, success)
    },
    //发送注册验证码--pc分享出来的注册
    registCodeNoAuth: (params, success) => {
        return request('GET', `${API}/v1/shop/account/noAuth/registCode`, params, success)
    },
    //校验注册验证码--pc分享出来的注册
    checkNoAuthRegistCode: (params, success) => {
        return request('POST', `${API}/v1/shop/account/noAuth/checkRegistCode`, params, success)
    },
    //快速注册-订货端注册优化优化
    checkQuickRegistCode: (params, success) => {
        return request('POST', `${API}/v1/shop/account/noAuth/quickRegist`, params, success)
    },
    //绑定刚注册的账号--pc分享出来的注册，完成注册后继续绑定
    completeNoAuthRegist: (params, success) => {
        return request('POST', `${API}/v1/shop/account/noAuth/completeRegist`, params, success)
    },
    //发送绑定验证码
    bindCode: (params, success) => {
        return request('GET', `${API}/v1/base/user/bindPhoneCode`, params, success)
    },
    //验证绑定手机的验证码
    checkBindCode: (params, success) => {
        return request('POST', `${API}/v1/base/user/checkBindPhoneCode`, params, success)
    },
    //绑定手机号
    bindPhone: (params, success) => {
        return request('POST', `${API}/v1/base/user/bindPhone`, params, success)
    },
    //订货端重置密码
    resetPasswordByShop: (params, success) => {
        return request('POST', `${API}/v1/shop/account/noAuth/resetPasswordByShop`, params, success)
    },
    //获取重置密码验证码---管理端
    resetCodeByManager: (params, success) => {
        return request('GET', `${API}/v1/account/resetCode`, params, success)
    },
    //管理端重置密码---管理端
    resetPasswordByManager: (params, success) => {
        return request('POST', `${API}/v1/account/resetPassword`, params, success)
    },
    //获取登陆用户信息
    getClientInfo: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getClientInfo`, params, success)
    },
    //获取登陆用户信息
    getClientById: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getClientById/${params}`, params, success)
    },
    //获取地址
    listByLogin: (params, success) => {
        return request('GET', `${API}/v1/shop/address/listByLogin`, params, success)
    },
    //获取地址
    deleteAddress: (params, success) => {
        return request('BODY', `${API}/v1/shop/address/delete`, params, success)
    },
    //保存、修改地址
    saveAddress: (params, success) => {
        return request('BODY', `${API}/v1/shop/address/save`, params, success)
    },
    //设置默认地址
    getUserInfo: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getShopUserInfo`, params, success)
    },
    // 保存用户客户信息
    saveUserClientInfo: (params, success) => {
        return request('POST', `${API}/v1/shop/account/saveUserClientInfo`, params, success)
    },
    // 保存用户头像
    updateUserHeadImg: (params, success) => {
        return request('POST', `${API}/v1/shop/account/updateUserHeadImg`, params, success)
    },
    //获取商品设置信息
    getGoodsSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/goodsSetting/getByCompanyId/${params}`, params, success)
    },
    //获取销售设置信息
    getSaleSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getSaleSetting`, params, success)
    },
    // 获取文件上传的token信息
    getUploadToken: (params, success) => {
        return request('GET', `${API}/v1/common/upload/getUploadToken`, params, success)
    },
    // 获取公司基本信息
    getCompanyInfo: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getCompanyById/` + params, params, success)
    },
    //取当前登录人关联的所有账号信息列表
    getAccountList: (params, success) => {
        return request('GET', `${API}/v1/base/user/getAccountList`, params, success)
    },
    //获取客户关联的员工列表
    getStaffList: (params, success) => {
        return request('GET', `${API}/v1/base/user/searchList`, params, success)
    },
    // 获取商城主题
    getThemeSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getThemeSetting/${params}`, params, success)
    },
    //获取当前登录用户的authCode
    getAuthCode: (params, success) => {
        return request('GET', `${API}/v1/account/getAuthCode`, params, success)
    },
    //获取当前登录用户的authCode
    getMobileShopVersion: (params, success) => {
        return request('GET', `${API}/v1/account/getMobileShopVersion`, params, success)
    },

    //获取省市县
    buildSimpleRegionTree: (params, success) => {
        return request('GET', `${API}/v1/common/helper/buildSimpleRegionTree`, params, success)
    },
    // 获取街道
    listStreetById: (params, success) => {
        return request('GET', `${API}/v1/common/helper/listStreetById/${params}`, params, success)
    },
    //获取微信jsticket
    getWxTicket: (params, success) => {
        return request('GET', `${API}/v1/shop/account/noAuth/getWxTicket`, params, success)
    },
    //获取微信jsticket/
    getWxQrcode: (params, success) => {
        return request('GET', `${API}/v1/shop/account/noAuth/getWxQrCode`, params, success)
    },
    //获取分享设置
    getShareSetting: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getShareSetting`, params, success)
    },
    //保存分享设置
    saveShareSetting: (params, success) => {
        return request('POST', `${API}/v1/shop/account/saveShareSetting`, params, success)
    },
    // 添加客服消息
    addMsgForClient: (params, success) => {
        return request('POST', `${API}/v1/client/client/addMsgForClient`, params, success)
    },
    // 获取指定对话消息列表
    searchMsgList: (params, success) => {
        return request('GET', `${API}/v1/client/client/searchMsgList`, params, success)
    },
    // 获取指定对话消息列表
    markReadByClient: (params, success) => {
        return request('POST', `${API}/v1/client/client/markReadByClient/${params}`, params, success)
    },
    // 获取消息对话列表
    searchGroupMsgListForClient: (params, success) => {
        return request('GET', `${API}/v1/client/client/searchGroupMsgListForClient`, params, success)
    },
    // 关键词搜索
    searchKeyWordList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchKeyWordList`, params, success)
    },

    // 个人中心
    getShopUserInfoForHome: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getShopUserInfoForHome`, params, success)
    },
    //会员信息
    getPaidMemberInfo: (params, success) => {
        return request('GET', `${API}/v1/shop/clientPaidMember/getPaidMemberInfo`, params, success)
    },
    // 获取商城帮助
    shopHelpTypeSearchList: (params, success) => {
        return request('GET', `${API}/v1/shopHelp/shopHelpType/noAuth/searchListForShop`, params, success)
    },
    // 获取微信用户信息
    getWeChatUserInfo: (params, success) => {
        return request('GET', `${API}/v1/setting/weChatSetting/noAuth/getWeChatUserInfo`, params, success)
    },
    // 微信openId登录
    openIdLogin: (params, success) => {
        return request('POST', `${API}/v1/account/openIdLogin`, params, success)
    },
    // 绑定微信openId
    bindOpenId: (params, success) => {
        return request('POST', `${API}/v1/account/bindOpenId`, params, success)
    },
    // 登录并绑定微信openId
    bindOpenIdForLogin: (params, success) => {
        return request('POST', `${API}/v1/account/bindOpenIdForLogin`, params, success)
    },
    // 保存/修改商城注册记录
    shopRegisterSave: (params, success) => {
        return request('BODY', `${API}/v1/shopRegister/noAuth/save`, params, success)
    },
    //
    getApplicationStatus: (params, success) => {
        return request('GET', `${API}/v1/application/noAuth/getApplicationStatus`, params, success)
    },
    //
    getSubsidiaryById: (params, success) => {
        return request('GET', `${API}/v1/shop/subsidiary/getById/${params}`, params, success)
    },
    // 添加访问日志
    visitLog: (params, success) => {
        return request('BODY', `${API}/v1/system/security/visitLog`, params, success)
    },
    // token是否在线
    isOnline: (params, success) => {
        return request('GET', `${API}/v1/account/isOnline`, params, success)
    },
};
//支付模块接口
export const PayFetch = {
    //构建支付宝支付参数
    buildWebAlipayApi: (params, success) => {
        return request('BODY', `${API}/v1/shop/pay/buildWebAlipayApi`, params, success)
    },
    //构建订单合并支付支付宝支付请求
    buildCombinedPayWebAlipayApi: (params, success) => {
        return request('POST', `${API}/v1/shop/pay/buildCombinedPayWebAlipayApi`, params, success)
    },
    //微信公众号支付
    pubWeixinApi: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/pubWeixinApi`, params, success)
    },
    weixinH5Api: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/weixinH5Api`, params, success)
    },
    //微信外h5订单合并支付
    weixinCombinedPayH5Api: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/weixinCombinedPayH5Api`, params, success)
    },
    // 合并付款 公众号支付
    weixinCombinedPayApi: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/weixinCombinedPayApi`, params, success)
    },
    //合并付款预存款和线下付
    combinedPayByOfflineOrPre: (params, success) => {
        return request('POST', `${API}/v1/finance/combinedPay/combinedPayByOfflineOrPre`, params, success)
    },
    //构建预存款充值支付宝支付请求
    buildPreBalanceRechargeAlipayApi: (params, success) => {
        return request('POST', `${API}/v1/shop/pay/buildPreBalanceRechargeAlipayApi`, params, success)
    },
    //预存款充值微信外h5支付
    weixinPreBalanceRechargeH5Api: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/weixinPreBalanceRechargeH5Api`, params, success)
    },
    //预存款充值微信公众号支付
    pubPreBalanceRechargeWeixinApi: (params, success) => {
        return request('GET', `${API}/v1/shop/pay/pubPreBalanceRechargeWeixinApi`, params, success)
    },
};
//代客下单
export const ClientFetch = {
    //创建订单
    saveByClient: (params, success) => {
        return request('POST', `${API}/v1/sale/valetSale/saveByClient`, params, success)
    },
    //根据规格ids串获取商品优惠信息
    getPromotionByProductIdsByClient: (params, success) => {
        return request('GET', `${API}/v1/sale/valetSale/getPromotionByProductIdsByClient`, params, success)
    },
    //获取订单优惠信息
    getPromotionByClient: (params, success) => {
        return request('GET', `${API}/v1/sale/valetSale/getPromotionByClient`, params, success)
    },
    //商城商品分页查询
    searchListForShopByClient: (params, success) => {
        return request('GET', `${API}/v1/sale/valetSale/noAuth/searchListForShopByClient`, params, success)
    },
    //客户列表
    searchList: (params, success) => {
        return request('GET', `${API}/v1/client/client/searchList`, params, success)
    },
    //新增客户
    addNewClient: (params, success) => {
        return request('POST', `${API}/v1/client/client/save`, params, success)
    },

};

export const ApplicationFetch = {
    getMiniProgram: (params, success) => {
        return request('GET', `${API}/v1/application/miniProgram/getByCompanyId/${params}`, params, success)
    },
    //获取授信额度信息
    searchCreditQuotaLogListForShop: (params, success) => {
        return request('GET', `${API}/v1/application/creditQuota/searchLogListForShop`, params, success)
    },
};

//积分商城模块
export const IntegralFetch = {
    //分页获取积分商品表列表
    searchList: (params, success) => {
        return request('GET', `${API}/api/v1/score/goods/searchList`, params, success)
    },
    //分页获取积分商品表列表
    getById: (params, success) => {
        return request('GET', `${API}/api/v1/score/goods/getById/${params}`, params, success)
    },
    //获取积分商品分类列表
    listAll: (params, success) => {
        return request('GET', `${API}/api/v1/score/goods/listAllType`, params, success)
    },
    //获取我的积分信息
    getMyScore: (params, success) => {
        return request('GET', `${API}//api/v1/buyer/mine/getMyScore`, params, success)
    },
    //获取我的积分明细列表
    getMyScoreLog: (params, success) => {
        return request('GET', `${API}/api/v1/buyer/mine/getMyScoreLog`, params, success)
    },
    //获取积分规则设置
    getRulesSetting: (params, success) => {
        return request('GET', `${API}/api/v1/buyer/mine/getRulesSetting`, params, success)
    },
    //创建积分订单
    createOrder: (params, success) => {
        return request('POST', `${API}/api/v1/score/order/createOrder`, params, success)
    },
    //分页获取积分订单信息列表
    searchOrderList: (params, success) => {
        return request('GET', `${API}/api/v1/score/order/searchList`, params, success)
    },
    //获取积分订单信息
    getOrderById: (params, success) => {
        return request('GET', `${API}/api/v1/score/order/getById/${params}`, params, success)
    },
    //根据快递单号获取物流信息
    getLogisticInfo: (params, success) => {
        return request('GET', `${API}/api/v1/common/data/getLogisticInfo`, params, success)
    },
    //根据快递单号获取物流信息
    getMySign: (params, success) => {
        return request('GET', `${API}/api/v1/buyer/mine/getMySign`, params, success)
    },
    //签到
    sign: (params, success) => {
        return request('GET', `${API}/api/v1/buyer/mine/sign`, params, success)
    },
    //签到
    getScoreCompanySetting: (params, success) => {
        return request('GET', `${API}/api/v1/buyer/mine/getScoreCompanySetting`, params, success)
    },
};

// 退货
export const SaleBackFetch = {
    // 申请退货
    save: (params, success) => {
        return request('BODY', `${API}/v1/sale/saleBack/save`, params, success)
    },
    // 退货列表
    searchList: (params, success) => {
        return request('GET', `${API}/v1/sale/saleBack/searchList`, params, success)
    },
    // 退货列表
    getById: (params, success) => {
        return request('GET', `${API}/v1/sale/saleBack/getById/${params}`, params, success)
    },
    // 查询可退订货单明细
    listEnableBackItem: (params, success) => {
        return request('GET', `${API}/v1/shop/order/listEnableBackItem/${params}`, params, success)
    },
};
// 销售中心
export const DistributionFetch = {
    //客户信息
    getByClientId: (params, success) => {
        return request('GET', `${API}/v1/distribution/distributionClientData/getByClientId/${params}`, params, success)
    },
    //手机端客户分销销售中心统计数据
    getCountDateByClientId: (params, success) => {
        return request('GET', `${API}/v1/distribution/distributionClientData/getCountDateByClientId/${params}`, params, success)
    },
    //手机端客户业绩统计
    getPerformanceStatistics: (params, success) => {
        return request('GET', `${API}/v1/distribution/distributionClientData/getPerformanceStatistics/${params}`, params, success)
    },
    //我的客户统计
    searchChildenClientList: (params, success) => {
        return request('GET', `${API}/v1/distribution/distributionClientData/searchChildenClientList`, params, success)
    },
    //订单统计
    searchOrderList: (params, success) => {
        return request('GET', `${API}/v1/distribution/distributionRebate/searchList`, params, success)
    },
    //保存提现账号
    saveAccount: (params, success) => {
        return request('POST', `${API}/v1/distribution/distributionClientData/save`, params, success)
    },
    //提现
    distributionWithdrawalSave: (params, success) => {
        return request('POST', `${API}/v1/distribution/distributionWithdrawal/save`, params, success)
    },
    //提现
    transferToFinance: (params1, params2, success) => {
        return request('POST', `${API}/v1/distribution/distributionWithdrawal/transferToFinance/${params1}/${params2} `, params1, success)
    },
    //账户明细
    searchLogList: (params, success) => {
        return request('GET', `${API}/v1/distribution/distributionPayLog/searchList`, params, success)
    },
    //提现记录
    searchLWithdrawalLogList: (params, success) => {
        return request('GET', `${API}/v1/distribution/distributionWithdrawal/searchList`, params, success)
    },
    //设置客户上级客户
    saveParentClient: (params, success) => {
        return request('POST', `${API}/v1/distribution/distributionClientData/saveParentClient`, params, success)
    },
    //推广商品列表
    searchListForShop: (params, success) => {
        return request('GET', `${API}/v1/distribution/distributionRebate/noAuth/searchListForShop`, params, success)
    },
    //最高佣金
    getGoodsCommissionById: (params, success) => {
        return request('GET', `${API}/v1/distribution/distributionRebate/noAuth/getGoodsById/${params}`, params, success)
    },
};
// 预存款
export const DepositFetch = {
    //查询企业的预存款充值设置明细
    searchItemListByLogin: (params, success) => {
        return request('GET', `${API}/v1/shop/preBalanceRecharge/searchItemListByLogin`, params, success)
    },
    //申请预存款充值充值
    applyRecharge: (params, success) => {
        return request('BODY', `${API}/v1/shop/preBalanceRecharge/applyRecharge`, params, success)
    },
    // 查询存款充值记录
    getById: (params, success) => {
        return request('POST', `${API}/v1/shop/preBalanceRecharge/getById/${params}`, params, success)
    },
};
// 代金券接口
export const VouchersFetch = {
    // 获取代金券列表
    searchClientVouchersList: (params, success) => {
        return request('GET', `${API}/v1/vouchers/vouchers/searchClientVouchersList`, params, success)
    },
    // 更新代金券列表
    updateIsReadByIds: (params, success) => {
        return request('GET', `${API}/v1/vouchers/vouchers/updateIsReadByIds/${params}`, params, success)
    },
    // 获取代金券明細
    searchVouchersLog: (params, success) => {
        return request('GET', `${API}/v1/vouchers/vouchersLog/searchList`, params, success)
    },
    // 根据商品获取代金券
    searchListByGoodsIdAndClientId: (params, success) => {
        return request('GET', `${API}/v1/vouchers/vouchers/searchListByGoodsIdAndClientId`, params, success)
    },
    // 根据规格获取代金券
    searchListByProductIdAndClientId: (params, success) => {
        return request('GET', `${API}/v1/vouchers/vouchers/searchListByProductIdAndClientId`, params, success)
    },
};
// 车型相关
export const SearchFetch = {
    // 汽车品牌搜索
    getAcBrandList: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesBrand/noAuth/getAcBrandList`, params, success);
    },
    // 汽车热门品牌
    getHotAcBrandList: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesBrand/noAuth/getHotAcBrandList`, params, success);
    },
    // 汽车车系搜索
    getAcVersionListByQuery: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesVersion/noAuth/getAcVersionListByQuery`, params, success);
    },
    // 汽车车型搜索
    getAcTypeList: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesType/noAuth/getAcTypeList`, params, success);
    },
    // 汽车车版本搜索
    getAcCarList: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesCar/noAuth/getAcCarList`, params, success);
    },
    // 汽车车版本搜索--分页
    searchAcCarList: (params, success) => {
        return request('GET', `${API}/v1/accessories/accessoriesCar/searchList`, params, success);
    },
    searchCarParc: (params, success) => {
        return request('GET', `${API}/v1/carparc/searchList`, params, success);
    },
    // // VIN码搜索车版本
    // getByVin: (params, success) => {
    //     return request('GET', `${API}/v1/accessories/accessoriesCar/noAuth/getByVin/${params}`, params, success);
    // },
    //根据OE码+车品牌 查询OE码关联的对应品牌下的车版本列表
    searchCarListByGoodsId: (params, success) => {
        return request('GET', `${API}/v1/accessories/goodsOeType/searchList`, params, success);
    },
    //根据商品ID+OE码 查询关联车型品牌列表
    getAcBrandListByGoodsId: (params, success) => {
        return request('BODY', `${API}/v1/accessories/goodsOeType/noAuth/getAcBrandListByGoodsId`, params, success);
    },
    // 根据OE码+车品牌 查询OE码关联的对应品牌下的车系列表
    getAcVersionListByGoodsIdAndAcBrandId: (params, success) => {
        return request('BODY', `${API}/v1/accessories/goodsOeType/noAuth/getAcVersionListByGoodsIdAndAcBrandId`, params, success);
    },
    // 根据OE码+车品牌 查询OE码关联的对应排量
    getAcDisplacementNameList: (params, success) => {
        return request('GET', `${API}//v1/accessories/accessoriesCar/noAuth/getAcDisplacementNameList`, params, success);
    },
    // 根据OE码+车品牌 查询OE码关联的对应年份
    getVehicleOfYearsList: (params, success) => {
        return request('GET', `${API}//v1/accessories/accessoriesCar/noAuth/getVehicleOfYearsList`, params, success);
    },

    //根据OE码+车品牌 查询OE码关联的对应品牌下的车版本列表
    getAcCarListByGoodsIdAndAcBrandAndAcVersion: (params, success) => {
        return request('BODY', `${API}/v1/accessories/goodsOeType/noAuth/getAcCarListByGoodsIdAndAcBrandAndAcVersion`, params, success);
    },
};

//员工相关
export const ClientAccountFetch = {
    //获取客户员工列表
    searchList: (params, success) => {
        return request('GET', `${API}/v1/shop/clientAccount/searchList`, params, success)
    },
    //新增/编辑客户员工
    saveClientAccount: (params, success) => {
        return request('BODY', `${API}/v1/shop/clientAccount/saveClientAccount`, params, success)
    },
    // 删除客户员工
    deleteClientAccount: (params, success) => {
        return request('BODY', `${API}/v1/shop/clientAccount/deleteClientAccount/${params}`, params, success)
    },
    // 重置员工密码
    modifyClientAccountPassword: (params, success) => {
        return request('POST', `${API}/v1/shop/clientAccount/modifyClientAccountPassword`, params, success)
    },
    // 创建订单草稿
    saveSaleOrderDraft: (params, success) => {
        return request('BODY', `${API}/v1/shop/clientAccount/saveSaleOrderDraft`, params, success)
    },
    // 获取待确认订单数量
    getWaitConfirmNumber: (params, success) => {
        return request('GET', `${API}/v1/shop/clientAccount/getWaitConfirmNumber`, params, success)
    },
    // 草稿订单分页查询
    searchSaleOrderDraftList: (params, success) => {
        return request('GET', `${API}/v1/shop/clientAccount/searchSaleOrderDraftList`, params, success)
    },
    // 删除订单草稿
    deleteSaleOrderDraft: (params, success) => {
        return request('POST', `${API}/v1/shop/clientAccount/deleteSaleOrderDraft/${params}`, params, success)
    },
    // 确认下单订单草稿
    confirmSaleOrderDraft: (_id, _orderId, success) => {
        return request('POST', `${API}/v1/shop/clientAccount/confirmSaleOrderDraft/${_id}/${_orderId}`, _id, success)
    },
};

export const EnquiryFetch = {
    batchSearchKeywordByInquirySheet : (params, success) => {
        return request('BODY', `${API}/v1/epc/batchSearchKeywordByInquirySheet`, params, success)
    },
    preGetInquirySheetResult : (params, success) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/preGetInquirySheetResult`, params, success)
    },
    searchKeywordByInquirySheet: (params, success, error) => {
        return request('GET', `${API}/v1/epc/searchKeywordByInquirySheet`, params, success, error)
    },
    imageRecognitionForVin : (params, success) => {
        return request('BODY', `${API}/v1/etopLive/noAuth/imageRecognitionForVin`, params, success)
    },
    getEpcImgDetail : (params, success) => {
        return request('BODY', `${API}/v1/epc/getEpcImgDetail`, params, success)
    },
    //根据Vin码搜索车版本
    getByVin: (params, success) => {
        return request('BODY', `${API}/v1/accessories/accessoriesCar/noAuth/getByVin`, params, success)
    },
    //分页获取产品组列表
    searchListForShop: (params, success) => {
        return request('GET', `${API}/v1/accessories/productGroup/noAuth/searchListForShop`, params, success)
    },
    //分页获取询价记录
    getClientSearchVinLong: (params, success) => {
        return request('GET', `${API}/v1/client/client/getClientSearchVinLong`, params, success)
    },
    //保存/修改询价单
    save: (params, success) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/save`, params, success)
    },
    //删除询价单
    deleteByIds: (params, success) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/deleteByIds`, params, success)
    },
    // 根据询价单 询价产品组ID 查询规格信息表
    getById: (params, success) => {
        return request('GET', `${API}/v1/shop/inquirySheet/getById/${params}`, params, success)
    },
    // 标记 员工是否已读(1:已读 0:未读)和维护客户最后一次浏览时间
    flagPersonnelHaveRead: (params, success, error) => {
        return request('GET', `${API}/v1/shop/inquirySheet/flagPersonnelHaveRead/${params}`, params, success, error)
    },
    // 更新询价单客户信息
    updateClientData: (params, success) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/updateClientData`, params, success)
    },
    // 根据询价单 询价产品组ID 查询规格信息表
    getGoodsByIdAndItemId: (params, success) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/getGoodsByIdAndItemId`, params, success)
    },
    // 根据询价单 询价产品组ID 查询规格信息表
    getGoodsListByItemId: (params, success) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/getGoodsListByItemId`, params, success)
    },
    // 分页获取询价单列表
    inquirySheetSearchList: (params, success) => {
        return request('GET', `${API}/v1/shop/inquirySheet/searchList`, params, success)
    },
    // 分页获取采购单列表
    buyingSheetSearchList: (params, success) => {
        return request('GET', `${API}/v1/shop/buyingRequisition/searchList`, params, success)
    },
    // 根据VIN+想查的状态码 获取询价单列表
    getListByVin: (params, success) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/getListByVin`, params, success)
    },
    // 根据VIN+想查的状态码 获取询价单列表
    deleteVinLog: (params, success) => {
        return request('BODY', `${API}/v1/client/clientSearchVinLog/deleteByClientId`, params, success)
    },
    // 根据VIN码 查询一级分类列表
    getGroupListByVinOrEpcId: (params, success) => {
        return request('BODY', `${API}/v1/epc/getMainGroup`, params, success)
    },
    // 根据一级分类 查询二级分类列表
    getSubgroup: (params, success) => {
        return request('BODY', `${API}/v1/epc/getSubGroup`, params, success)
    },
    // 选中了二级分类 查询三级分类列表
    getParts: (params, success) => {
        return request('BODY', `${API}/v1/epc/getParts`, params, success)
    },

    // 保存配件备注
    saveImgAndRemark: (params, success) => {
        return request('BODY', `${API}/v1/shop/buyingRequisition/saveImgAndRemark`, params, success)
    },
    // 保存配件备注
    getByInquiryItemId: (params, success) => {
        return request('GET', `${API}/v1/shop/buyingRequisition/getByInquiryItemId/${params}`, params, success)
    },
    // 保存配件备注
    updateFormStatusToHavePriceById: (params, success) => {
        return request('GET', `${API}/v1/shop/inquirySheet/updateFormStatusToHavePriceById/${params}`, params, success)
    },
    // 配置信息
    getCarConfigById: (params, success, error) => {
        return request('GET', `${API}/v1/accessories/accessoriesCar/getById/${params}`, params, success, error)
    },
    // 询价单标记信息
    getInquirySheetItemGoodsList: (params, success, error) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/getInquirySheetItemGoodsList`, params, success, error)
    },
    // 保养件
    getOrderQuicklyList: (params, success, error) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/getOrderQuicklyList`, params, success, error)
    },
    // 保养件
    searchListForSelect: (params, success, error) => {
        return request('GET', `${API}/v1/accessories/productGroup/searchListForSelect`, params, success, error)
    },
    // 分享保存
    quotedPriceShareSave: (params, success, error) => {
        return request('BODY', `${API}/v1/shop/quotedPriceShare/save`, params, success, error)
    },
    // 快速下单
    quotedPriceShareGetById: (params, success, error) => {
        return request('GET', `${API}/v1/shop/quotedPriceShare/getById/${params}`, params, success, error)
    },
    // 整单重询
    createByCopy: (params, success, error) => {
        return request('GET', `${API}/v1/shop/inquirySheet//createByCopy/${params}`, params, success, error)
    },
    // 标准名称分组
    productGroupLevelSearch: (params, success, error) => {
        return request('GET', `${API}/v1/accessories/productGroupLevel/searchList`, params, success, error)
    },
    // 三级标准名称分组
    productGroupSearch: (params, success, error) => {
        return request('GET', `${API}/v1/accessories/productGroup/searchList`, params, success, error)
    },
    // 根据VIN校验
    checkoutVin: (params, success, error) => {
        return request('BODY', `${API}/v1/shop/inquirySheet/checkoutVin`, params, success, error)
    },
    // 查询品牌件号列表
    getItemCountList: (params, success, error) => {
        return request('BODY', `${API}/v1/accessories/oeToCarItemCount/getItemCountList`, params, success, error)
    },
};

export const PingPlusPlusFetch = {
    buildCharge: (params, success) => {
        return request('BODY', `${API}/v1/pingplusplus/pingplusplus/buildCharge`, params, success)
    },
    buildOrder: (params, success) => {
        return request('BODY', `${API}/v1/pingplusplus/pingplusplus/buildOrder`, params, success)
    },
    memberUpgradePayOrder: (params, success) => {
        return request('BODY', `${API}/v1/pingplusplus/pingplusplus/memberUpgradePayOrder`, params, success)
    },
    listPayType: (params, success) => {
        return request('GET', `${API}/v1/pingplusplus/pingplusplus/listPayType/${params}`, params, success)
    },
};

export const CouponFetch = {
    //获取客户优惠券
    searchCouponRecord: (params, success) => {
        return request('GET', `${API}/v1/shop/coupon/searchCouponRecord`, params, success)
    },
    //获取客户相关优惠券活动
    listCouponActivityByShop: (params, success) => {
        return request('GET', `${API}/v1/shop/coupon/listCouponActivityByShop`, params, success)
    },
    //领取优惠券
    receiveCoupon: (params, success) => {
        return request('POST', `${API}/v1/shop/coupon/receiveCoupon`, params, success)
    },
    //批量领取优惠券
    batchReceiveCoupon: (params, success) => {
        return request('POST', `${API}/v1/shop/coupon/batchReceiveCoupon`, params, success)
    },
};
export const SubsidiarySalePurchaseFetch = {
    getHomeInfo: (params, success) => {
        return request('GET', `${API}/v1/sale/subsidiarySalePurchase/getHomeInfo`, params, success)
    },
    // 分页查询采购中明细列表
    searchPurchasingItemList: (params, success) => {
        return request('GET', `${API}/v1/sale/subsidiarySalePurchase/searchPurchasingItemList`, params, success)
    },
    // 分页查询待入库明细列表
    searchWaitInItemList: (params, success) => {
        return request('GET', `${API}/v1/sale/subsidiarySalePurchase/searchWaitInItemList`, params, success)
    },
    // 取消入库
    cancelIn: (params, success) => {
        return request('BODY', `${API}/v1/sale/subsidiarySalePurchase/cancelIn`, params, success)
    },
    // 确认入库
    confirmIn: (params, success) => {
        return request('BODY', `${API}/v1/sale/subsidiarySalePurchase/confirmIn`, params, success)
    },
    // 分页查询分支规格库存
    searchSubsidiaryProductStock: (params, success) => {
        return request('GET', `${API}/v1/sale/subsidiarySalePurchase/searchSubsidiaryProductStock`, params, success)
    },
};
