const hostname = window.location.hostname;
const prodHost = 'qipeidd';
const testHost = 'dh688';
const devHost = `http://${hostname}`;
const isDefaultUrl = !(hostname.indexOf(prodHost) === -1 && hostname.indexOf(testHost) === -1 && hostname.indexOf('192.168') === -1 && hostname.indexOf('localhost') === -1);
const UrlUtil = {
    getAPI() {
        if (process.env.API_ENV === 'production') {
            return `https://api.qipeidd.com`;
        } else if (process.env.API_ENV === 'test') {
            return `http://api.dh688.cn`;
        } else {
            return `${devHost}:7002`;
            // return `http://192.168.0.99:3002`;
            // return `http://api.dh688.cn`;
            // return `https://api.qipeidd.com`;
        }
    },
    getAccountUrl: (companyID) => {

        if (process.env.API_ENV === 'production') {
            return isDefaultUrl ? `https://store${companyID}.${prodHost}.com` : window.location.origin;
        } else if (process.env.API_ENV === 'test') {
            return isDefaultUrl ? `http://store${companyID}.${testHost}.cn` : window.location.origin;
        } else if (process.env.API_ENV === 'development') {
            return `${devHost}:7001`;
        }

        //
        // if (process.env.API_ENV === 'production') {
        //     return companyID ? `https://store${companyID}.${prodHost}.com` : `https://account.${prodHost}.com`
        // } else if (process.env.API_ENV === 'test') {
        //     return companyID ? `http://store${companyID}.${testHost}.cn` : `http://account.${testHost}.cn`
        // } else if (window.location.href.indexOf('192.168')) {
        //     return `${devHost}:7001`;
        // }
    },
    getStoreAccountUrl: (company) => {
        if (process.env.API_ENV === 'production') {
            return `https://store${company}.${prodHost}.com`
        } else if (process.env.API_ENV === 'test') {
            return `http://store${company}.${testHost}.cn`
        } else {
            return `${devHost}:7001`;
        }
    },
    getMShopUrl: () => {
        if (process.env.API_ENV === 'production') {
            return `https://m.${prodHost}.com`
        } else if (process.env.API_ENV === 'test') {
            return `http://m.${testHost}.cn`
        } else {
            return `${devHost}:7005`;
        }
    },
    getMMgrUrl() {
        if (process.env.API_ENV === 'production') {
            // return `http://mtest.qipeidd.cn`;
            return `https://mgr.${prodHost}.com`;
        } else if (process.env.API_ENV === 'test') {
            return `http://mgr.${testHost}.cn`;
        } else if (process.env.API_ENV === 'development') {
            return `${devHost}:7004`;
        }
    },

};
export default UrlUtil;
