import React from 'react';
import {Router, Route, Switch} from 'dva/router';
import Dynamic from 'dva/dynamic';
import PageLayout from "./layout/page-layout";
import routers from './router-config';

function RouterConfig({history, app}) {
    return (
        <Router history={history}>
            <PageLayout app={app}>
                <Switch>
                    {
                        routers.map(({path, title, isDefault, ...route}, index) => (
                            <Route
                                exact
                                key={index}
                                path={path}
                                component={Dynamic({
                                    app,
                                    ...route,
                                })}
                            />
                        ))
                    }
                </Switch>
            </PageLayout>
        </Router>
    );
}

export default RouterConfig;
