import {onFriends, onSyncFriendAction} from './friends'
import {onMyInfo, onUpdateMyInfo, onUsers, onUpdateUser} from './card'
import {onSessions, onUpdateSession} from './sessions'
import {
    onOfflineSysMsgs, onSysMsg, onUpdateSysMsg, onSysMsgUnread,
    onUpdateSysMsgUnread, onOfflineCustomSysMsgs, onCustomSysMsg
} from './sysmsg'

import InitUtil from "../init-util";
import {Howl} from 'howler';

// Setup the new Howl.
const sound = new Howl({
    src: ['//img.qipeidd.com/Bell.mp3']
});

const SDK = require('./sdk/NIM_Web_SDK_v8.1.0');
export const initYxSdkConf = ({state = {}, options, subsidiary_id_, dispatch}) => {
    var data = window.data = {};
    const params = {
        debug: false,
        db: true,//是否开启本地数据库存储数据
        nosScene: 'im',//nos存储场景 默认im
        nosSurvivalTime: Infinity,//nos存储场景有效时间 默认Infinity
        uploadUrl: undefined, // 私有化部署方案对文件处理专用接口
        downloadUrl: undefined,
        syncSessionUnread: true,
        enabledHttpsForMessage: true,//针对 iOS 对 https 链接的要求而设计，若设置为 true，则所有文件类型消息的链接转为 https
        needReconnect: true,//连接失败时是否需要自动重连
        autoMarkRead: false,//对收到的消息，是否自动标记为已读
        ntServerAddress: null,//连接统计上报地址，设为null则不上报，用户可填写自己的日志上报地址，不填则默认为云信服务器的统计地址
        appKey: '2918e1f75ebd4feb5234bbeb9a014f3a',//'2898954d6c7acdd476442f9c78fd76a6',//  在云信管理后台查看应用的 appKey
        account: '',// 用户账号 必须唯一
        token: '',// 用户密码
        customTag: 'WEB', // 客户端自定义tag，最大32个字符
        privateConf: undefined,//私有化部署所需的配置
        //-------- 连接相关 ----------
        onconnect: onConnect,//连接建立后的回调(登录成功), 会传入一个对象, 包含登录的信息, 有以下字段
        onerror: onError,//发生错误的回调, 会传入错误对象
        onwillreconnect: onWillReconnect, // 即将重连的回
        ondisconnect: onDisconnect,// 断开连接后的回调
        //--------- 连接相关 ---------
        //--------- 用户相关 ---------
        onloginportschange: onLoginPortsChange,  // 多端登录
        syncFriends: true,//是否同步好友列表
        onfriends: (friends) => onFriends(friends, data), // 用户关系 - 好友
        onsyncfriendaction: (obj) => onSyncFriendAction(obj),
        onmyinfo: (user) => onMyInfo(user, data), //我的名片
        onupdatemyinfo: (user) => onUpdateMyInfo(user, data), // 名片更新
        syncFriendUsers: true, // 是否同步好友对应的用户名片列表
        onusers: (users) => onUsers(users, data), //好友的名片
        onupdateuser: (user) => onUpdateUser(user, data), // 好友名片更新
        //--------- 用户相关 ---------

        //--------- 会话相关 ---------
        onsessions: (sessions) => onSessions(sessions, data), // 会话
        onupdatesession: (sessions) => onUpdateSession(sessions, data, dispatch), // 会话更新
        //--------- 会话相关 ---------
        //--------- 消息相关 ---------
        syncRoamingMsgs: true, // 是否同步漫游消息
        onroamingmsgs: onRoamingMsgs, // 漫游消息 同步漫游消息对象的回调, 每个会话对象对应一个回调, 会传入消息数组
        onofflinemsgs: onOfflineMsgs, // 离线消息 同步离线消息对象的回调, 每个会话对象对应一个回调, 会传入消息数组
        onmsg: onMsg, //  收到消息对象的回调 当前登录帐号在其它端发送消息之后也会收到此回调, 注意此时消息对象的from字段就是当前登录的帐号
        // 可以调用nim.mergeMsgs来合并数据
        //--------- 消息相关 ---------
        //--------- 系统通知相关 ---------
        syncMsgReceipts: true, // 是否同步已读回执时间戳
        onofflinesysmsgs: (sysMsgs) => onOfflineSysMsgs(sysMsgs, data), // 离线系统通知
        onsysmsg: (sysMsg) => onSysMsg(sysMsg, data), // 收到系统通知
        onupdatesysmsg: (sysMsg) => onUpdateSysMsg(sysMsg, data),
        onsysmsgunread: (obj) => onSysMsgUnread(obj, data), // 收到系统通知未读数
        onupdatesysmsgunread: (obj) => onUpdateSysMsgUnread(obj, data), // 系统通知未读数更新了
        onofflinecustomsysmsgs: (sysMsgs) => onOfflineCustomSysMsgs(sysMsgs), // 离线自定义系统通知
        oncustomsysmsg: (sysMsg) => onCustomSysMsg(sysMsg), // 收到自定义系统通知
        //--------- 系统通知相关 ---------
        //--------- 同步完成 ---------
        onsyncdone: onSyncDone, // 回调之后表示 SDK 完成了数据同步工作, 此时可以进行渲染 UI 等操作了。


    };

    Object.assign(params, options);
    window.nim = state.nim = SDK.NIM.getInstance(params);
    // 静默登录，链接状态仅打印
    // 链接成功
    function onConnect() {
        console.log('连接成功');
    }

    // 链接断开，重连
    function onWillReconnect(obj) {
        // 此时说明 `SDK` 已经断开连接, 请开发者在界面上提示用户连接已断开, 而且正在重新建立连接
        console.log('即将重连', obj);
    }

    // 链接断开
    function onDisconnect(error) {
        // 此时说明 `SDK` 处于断开状态, 开发者此时应该根据错误码提示相应的错误信息, 并且跳转到登录页面
        // 静默登录，这里不给用户提示，只打印
        console.log('连接断开', error);
        if (error) {
            switch (error.code) {
                // 账号或者密码错误, 请跳转到登录页面并提示错误
                case 302:
                    console.log('账号或者密码错误')
                    break;
                // 重复登录, 已经在其它端登录了, 请跳转到登录页面并提示错误
                case 417:
                    console.log('重复登录, 已经在其它端登录了')
                    break;
                // 被踢, 请提示错误后跳转到登录页面
                case 'kicked':
                    console.log('被踢')
                    break;
                default:
                    break;
            }
        }
    }

    // 链接错误
    function onError(error, obj) {
        console.log('发生错误', error, obj);
    }

    // 当前登录帐号在其它端的状态发生改变了
    function onLoginPortsChange(loginPorts) {
        console.log('当前登录帐号在其它端的状态发生改变了', loginPorts);
    }

    function onRoamingMsgs(obj) {
        console.log('漫游消息', obj);
        pushMsg(obj.msgs);
    }

    function onOfflineMsgs(obj) {
        console.log('离线消息', obj);
        pushMsg(obj.msgs);
    }

    function onMsg(msg) {
        console.log('收到消息', msg.scene, msg.type, msg);

        setTimeout(() => {
            sound.play()
        })

        dispatch({
            type: 'saveState',
            payload: {
                newSessions: msg
            }
        })
        pushMsg(msg);
    }

    function pushMsg(msgs) {
        if (!Array.isArray(msgs)) {
            msgs = [msgs];
        }
        var sessionId = msgs[0].sessionId;
        data.msgs = data.msgs || {};
        data.msgs[sessionId] = JSON.parse(JSON.stringify(window.nim.mergeMsgs(data.msgs[sessionId], msgs)));
        dispatch({
            type: 'saveState',
            payload: {
                msgs: Object.assign({}, data.msgs)
            }
        })
    }


    function onSyncDone() {
        console.log('同步完成', data);
        data.sync = true;
        dispatch({
            type: 'saveState',
            payload: {
                ...data
            }
        })

        window.nim.getLocalSessions({
            limit: 100,
            done: getLocalSessionsDone
        });

        function getLocalSessionsDone(error, obj) {
            // console.log('获取本地会话列表' + (!error ? '成功' : '失败'), error, obj);
            if (!error) {
                onSessions(obj.sessions, data);
            }
        }

        // 同步完成后才去查运营商的帐号，不然获取不到云信帐号信息
        // 平台不获取
        if (subsidiary_id_ !== 1) {
            InitUtil.getByFkIdAndType({id: subsidiary_id_, type: 1}).then(rs => {
                if (rs.status && rs.data) {
                    window.nim.getUser({
                        account: rs.data.acc_id_,
                        sync: true,
                        done: (err, user) => {
                            if (!err) {
                                dispatch({
                                    type: 'saveState',
                                    payload: {
                                        imCurrentFriend: user
                                    }
                                })
                            }
                        }
                    })
                }

            })
        }
    }
};


