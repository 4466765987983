const UA = localStorage.getItem('wkData-webUA') || window.navigator.userAgent;

const AppUtil = {
    isApp: (UA.indexOf('YunShlApp') > -1),
    isIos: (UA.indexOf('YunShlApp/ios') > -1),
    isAndroid: (UA.indexOf('YunShlApp/android') > -1),
    loadToken: () => {
        return jsCallOcLoadMethod('loadToken');
    },
    loadAPI: () => {
        return jsCallOcLoadMethod('loadAPI')
    },
    loadState: () => {
        return jsCallOcLoadMethod('loadState')
    },
    loadUser: () => {
        return jsCallOcLoadMethod('loadUser')
    },
    pingPay: (json) => {
        if (AppUtil.isIos) {
            window.webkit.messageHandlers.pingPay.postMessage(json);
        }
    },
    loadVersion: () => {
        return jsCallOcLoadMethod('loadVersion', 'true')
    },
    deleteUserInfo: () => {
        return jsCallOcLoadMethod('deleteUserInfo')
    },
    logout: (msg) => {
        AppUtil.jsCallAppParam(2, msg);
    },
    cleanCache: () => {
        return jsCallOcLoadMethod('cleanCache')
    },
    loadMac: () => {
        return jsCallOcLoadMethod('loadMac')
    },
    loadExInfo: () => {
        return jsCallOcLoadMethod('loadExInfo')
    },
    saveUserInfo: (v) => {
        return jsPostOcLoadMethod('saveUserInfo', v)
    },
    changeOrientation: (v) => {
        return jsPostOcLoadMethod('changeOrientation', {direction: v})
    },
    // 跳转扫码下单
    openScan: (params) => {
        return jsPostOcLoadMethod('openScan', params)
    },
    // App扫码
    openAppScan: (params) => {
        return jsPostOcLoadMethod('openAppScan', params)
    },
    // Vin扫码
    openVinScan: (params) => {
        return jsPostOcLoadMethod('openVinScan', params)
    },
    // 保存扫码清单
    saveScanList: (params) => {
        if (AppUtil.isAndroid) {
            window.ysDingHuo.saveScanList(JSON.stringify(params));
        } else if (AppUtil.isIos) {
            window.webkit.messageHandlers.saveScanList.postMessage(JSON.stringify(params));
        }
    },
    // 获取扫码清单
    loadScanList: () => {
        return jsCallOcLoadMethod('loadScanList')
    },
    // 扫码下单返回代客下单
    backOrderCreate: () => {
        return jsCallOcLoadMethod('backOrderCreate');
    },
    // 打印标签
    goPrint: () => {
        return jsCallOcLoadMethod('goPrint')
    },
    // 开启地址切换
    openIpSetting: (params) => {
        return jsPostOcLoadMethod('openIpSetting', params)
    },

    // 打开大图
    goBigImage: (index, urlArr) => {
        if (AppUtil.isIos) {
            window.webkit.messageHandlers.goBigImage.postMessage(JSON.stringify({type: index, params: urlArr}));
        } else if (AppUtil.isAndroid) {
            window.ysDingHuo.goBigImage(index, JSON.stringify(urlArr));
        }
    },
    // 保存图片到本地
    saveDomToImg: (baseUrl) => {
        jsPostOcLoadMethod("saveDomToImg", baseUrl)
    },
    // 分享图片
    shareToImg: (baseUrl) => {
        console.log('shareToImg')
        console.log('baseUrl', baseUrl)
        jsPostOcLoadMethod("shareToImg", baseUrl)
    },
    // url分享图片
    shareUrl: (params) => {
        jsPostOcLoadMethod("shareUrl", params)
    },
    // 扫描蓝牙设备
    findBleDevices: (params) => {
        jsPostOcLoadMethod("findBleDevices", params);
    },
    // 图片打印
    printImg: (baseUrl) => {
        return jsPostOcLoadMethod('printImg', baseUrl)
    },
    // toLink: (state, name, query, title, exInfo) => {
    //     //区分ios android调用app跳转页面
    //     let queryString = '';
    //     if (typeof query !== 'undefined') {
    //         for (let key in query) {
    //             queryString += '&' + (key + '=' + query[key])
    //         }
    //         queryString = queryString.substr(1);
    //     }
    //
    //     AppUtil.jsCallAppParam(0, {
    //         pathname: name,
    //         query: queryString,
    //         title: title,
    //         state: state,
    //         exInfo: exInfo
    //     });
    // },
    //打开外部链接
    goWeb: (url, title) => {
        if (AppUtil.isIos) {
            window.webkit.messageHandlers.goWeb.postMessage(JSON.stringify({url: url, params: title}));
        } else if (AppUtil.isAndroid && window.ysDingHuo.goWeb) {
            window.ysDingHuo.goWeb(url, title);
        }
    },
    getPosition: () => {
        try {
            return jsPostOcLoadMethod('getPosition')
        } catch (e) {
            console.log(e)
        }
    },
    // 修改状态栏颜色
    changeAppBar: (params) => {
        if (window.ysDingHuo && window.ysDingHuo.changeAppBar) {
            window.ysDingHuo.changeAppBar(JSON.stringify(params));
        }
    },
    jsCallAppParam: (type, params) => {
        if (AppUtil.isAndroid) {
            return window.ysDingHuo.jsCallAppParam(type, JSON.stringify(params));
        } else if (AppUtil.isIos) {
            window.webkit.messageHandlers.jsCallAppParam.postMessage(JSON.stringify({type: type, param: params}));
        }
    },
    // 微信支付
    applyWxPay: (url) => {
        const params = {
            Referer: 'm.qipeidd.com://',
            wxpay: 'true',
            url: url,
        };
        if (AppUtil.isAndroid) {
            window.ysDingHuo.applyWxPay(JSON.stringify(params));
        } else if (AppUtil.isIos) {
            window.webkit.messageHandlers.applyWxPay.postMessage(JSON.stringify(params));
        }
    },
    // 微信支付--订单
    applyWxPayByOrderId: (orderId) => {
        jsPostOcLoadMethod('applyWxPayByOrderId', orderId);
    },
};

function jsPostOcLoadMethod(method, params) {
    if (AppUtil.isAndroid) {
        window.ysDingHuo[method](JSON.stringify(params));
    } else if (AppUtil.isIos) {
        window.webkit.messageHandlers[method].postMessage(JSON.stringify({param: params}));
    }
}


//js 调用 oc 的方法-需要返回值(load 方法咯)
function jsCallOcLoadMethod(methodName, params) {
    if (!methodName) return;
    if (AppUtil.isAndroid && window.ysDingHuo[methodName]) {
        if (params) {
            return window.ysDingHuo[methodName](params)
        } else {
            return window.ysDingHuo[methodName]()
        }
    } else if (AppUtil.isIos) {
        //1:先调一下oc的方法
        window.webkit.messageHandlers[methodName].postMessage(params || null);
        //2:再去本地查找预加载传过来存在本地的值
        return localStorage.getItem('wkData-' + methodName);
    }
}


export default AppUtil;

