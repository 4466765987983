import {routerRedux} from "dva/router";
import {Base64} from 'js-base64';
import qs from 'qs';
import pathToRegexp from 'path-to-regexp';
import routers from '../router-config';
import CommonUtil from "../utils/common-util";
import StorageUtil from "../utils/storage-util";
import {LocalConst, SessionConst} from "../utils/constant";
import InitUtil from "../utils/init-util";
import AppUtil from "../utils/app-util";
import {ShopFetch, SystemFetch} from "../services/service";
import {initYxSdkConf} from "../utils/yx-im";
import vConsole from 'vconsole'

export default {

    namespace: 'appModel',

    state: {},

    subscriptions: {
        // 初始化数据
        init({dispatch, history}) {
            if (process.env.API_ENV !== "production") {
                new vConsole()
            }
            const {location} = history;

            // 获取登陆用户信息
            let ysUser = StorageUtil.getItem(LocalConst.mShop_user);
            const companyTheme = StorageUtil.getItem(LocalConst.companyTheme);
            let query = CommonUtil.getSearchFromUrl();
            let companyId = StorageUtil.getItem(LocalConst.companyId);

            //不同公司id,清除本地缓存
            if (query.companyId && (+query.companyId !== companyId)) {
                localStorage.clear();
                companyId = +query.companyId;
                // 更新企业id
                StorageUtil.setItem(LocalConst.companyId, +query.companyId);
            }

            // webapp登陆跳转
            if (query.auth_code_) {
                const auth_code_ = JSON.parse(Base64.decode(query.auth_code_));
                ysUser = auth_code_;
                // 本地保存登录信息
                CommonUtil.saveStorageFormLogin(auth_code_);
                let pathname = auth_code_.prevPathname ? Base64.decode(auth_code_.prevPathname) : location.pathname;
                dispatch(routerRedux.replace({
                    pathname: pathname,
                    search: `companyId=${companyId}`
                }));
            }
            // 分享人
            if (query.u) {
                StorageUtil.setItem(LocalConst.shareUserId, query.u, true)
            }
            //分享客户
            if (query.c) {
                StorageUtil.setItem(LocalConst.shareClientId, query.c, true)
            }
            if (query.code) {
                //state参数有带V的字符的,是微信授权的-- 没带V的不用进入跑接口,以防在做微信支付也需要用到返回的code时,报code已使用的错误
                if (query.state && query.state.indexOf('V') > -1) {
                    console.log('获取微信授权信息', query.code);
                    // 获取微信授权信息
                    InitUtil.getWechatUserInfo(query.code, companyId).then(rs => {
                        if (ysUser) {
                            InitUtil.getCartNumber();
                            InitUtil.getClientInfo();
                        }
                    });
                }
            }

            // 设置主题色
            if (companyTheme || (ysUser && ysUser.company_theme_)) {
                CommonUtil.setTheme(companyTheme || (ysUser && ysUser.company_theme_));
            }
            // 不存在companyId，跳转登陆
            if (!companyId) {
                // Toast.info(`缺少企业id,${window.location.href}`);
                return;
            }
            // 判断是否体验企业
            const isExperience = !!CommonUtil.checkExperience(companyId);
            // 设置体验账号信息
            StorageUtil.setItem(LocalConst.isExperience, isExperience);


            // 体验账号,没有登陆人或者登录人id不等于url新id
            if (isExperience && (!ysUser || ysUser.company_id_ !== companyId)) {
                // 自动登陆体验账号，获取公司设置信息
                CommonUtil.toShopExperience();
            }

            // 获取购物车数量
            if (ysUser && !query.code) {
                // 重置优惠券领取状态
                StorageUtil.setItem(SessionConst.couponReceive, false, true);
                InitUtil.getCartNumber();
                InitUtil.getClientInfo();
                InitUtil.getSubsidiaryById(ysUser.subsidiary_id_);
                // 获取当前登录客户的云信帐号
                // 登录云信
                InitUtil.getByFkIdAndType({id: ysUser.client_id_, type: 3}).then(rs => {
                    if (rs.status && rs.data)
                        initYxSdkConf({
                            options: {account: rs.data.acc_id_, token: rs.data.token_},
                            subsidiary_id_: ysUser.subsidiary_id_,
                            dispatch
                        });
                    // initYxSdkConf({options: {account: 'zhanghao2', token: '123456'}, dispatch});
                })
            }
            // 获取企业应用
            InitUtil.getApplicationStatus(companyId);
            // 商城设置信息
            InitUtil.getShopCompanySetting(companyId);
            // 注册设置
            InitUtil.getShopRegisterSetting(companyId);
            // 商品设置信息
            InitUtil.getGoodsSetting(companyId);
            // 商品分类信息
            InitUtil.listTypeByCompany(companyId);
            // 商品属性信息
            // InitUtil.getUsePropertyTree(companyId);

            // 获取公司详细信息
            InitUtil.getCompany(companyId).then(rs => {
                if (rs.status === 1) {
                    if (!rs.data.allow_visitor_ && !isExperience && !ysUser) {
                        // 非体验账号不允许游客访问，退出登陆端
                        // BusinessUtil.toLogout();
                        // return;
                    }
                    StorageUtil.setItem(LocalConst.companyName, rs.data.name_);
                    StorageUtil.setItem(LocalConst.companyTheme, rs.data.theme_);
                    document.title = rs.data.shop_home_title_ || rs.data.name_;

                    // 检查用户是否微信绑定
                    if (!query.code) {
                        CommonUtil.toWechatBind();
                    }
                    //设置浏览器小icon
                    CommonUtil.setIco(rs.data.mini_web_icon_);
                    CommonUtil.setTheme(rs.data.theme_);

                    // 创建统计代码
                    CommonUtil.setStatisticalCode(rs.data.view_count_url_);


                }
            });
            // 删除微信设置信息
            StorageUtil.delItem(LocalConst.wechatData);
            // app 安卓修改状态栏颜色
            AppUtil.changeAppBar({color: `#fff`, titleColor: 1});
        },

        listen({history, dispatch}) {
            // 监听 history 变化，记录上一个路由
            return history.listen((location, action) => {
                // state数据过大可能导致错误
                const newLocation = {...location, state: null};
                const query = qs.parse(location.search, {ignoreQueryPrefix: true});
                const companyId = StorageUtil.getItem(LocalConst.companyId);
                const localHistory = StorageUtil.getItem(SessionConst.localHistory, true) || [];
                const historyLen = localHistory.length;
                if (action === 'POP') {
                    localHistory.shift()
                } else {
                    if (historyLen > 0 && localHistory[0].pathname === newLocation.pathname) {
                        localHistory.splice(0, 1, newLocation)
                    } else {
                        localHistory.unshift(newLocation);
                    }
                    if (historyLen > 8) {
                        localHistory.pop()
                    }

                }

                StorageUtil.setItem(SessionConst.localHistory, localHistory, true);
                StorageUtil.setItem(SessionConst.prevLocation, localHistory[1] || {}, true);
                if (!query.companyId && companyId) {
                    query.companyId = companyId;
                    location.search = qs.stringify(query);
                    history.replace(location);
                } else {
                    // 添加访问记录
                    const routerPro = routers.find(router => pathToRegexp(router.path).exec(location.pathname));
                    if (!query.auth_code_) {
                        SystemFetch.visitLog({
                            url_: window.location.href,
                            title_: routerPro.title,
                        });
                    }
                }

            });
        }
    },

    effects: {},

    reducers: {
        saveState(state, action) {
            return {...state, ...action.payload};
        },
    },

};
