import React, {Fragment} from 'react';
import {connect} from 'dva';
import classnames from 'classnames';

import {withRouter} from 'dva/router';

import AppUtil from "../utils/app-util";

const pageCls = classnames('page-box', {
    'page-ios': AppUtil.isIos
});
const PageLayout = ({appModel, children, dispatch, location: {pathname}, ...props}) => {
    // 获取页面设置
    return (
        <section className={pageCls}>
            <Fragment>{children}</Fragment>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {...state}
};
export default withRouter(connect(mapStateToProps)(PageLayout));
