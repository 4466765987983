const routers = [
    {
        title: '商城首页',
        path: '/',
        models: () => [import('./models/home/home-model')],
        component: () => import('./routes/home/home-route'),
    },
    {
        title: '商城首页',
        path: '/home',
        models: () => [import('./models/home/home-model')],
        component: () => import('./routes/home/home-route'),
    },
    {
        title: '车型选择',
        path: '/car/select', // 车型选择
        models: () => [import('./models/search/car-select-model')],
        component: () => import('./routes/search/car-select'),
    },
    {
        title: '关键词搜索',
        path: '/search/goods', // 关键词搜索
        component: () => import('./routes/search/goods-search'),
    },
    {
        title: '查询结果页',
        path: '/search/result', // 查询结果页
        models: () => [import('./models/search/search-result-model')],
        component: () => import('./routes/search/search-result-route'),
    },
    {
        title: '按VIN码找件',
        path: '/search/vin',// 按VIN码找件
        component: () => import('./routes/search/vin-route'),
    },

    {
        title: '按分类查找',
        path: '/goods/classify/:type?',// 按分类查找
        component: () => import('./routes/goods/classify/index'),
    },
    {
        title: '商品列表',
        path: '/goods/list',// 商品列表
        models: () => [import('./models/goods/goods-list-model')],
        component: () => import('./routes/goods/goods-list-route'),
    },
    {
        title: '商品详情',
        path: '/goods/detail/:id/:productId?', //商品详情
        component: () => import('./routes/goods/goods-detail-route'),
    },
    {
        title: '商品列表（二级页）',
        path: '/goods/list/label',// 商品列表（二级页）
        component: () => import('./routes/search/goods-list-label'),
    },

    {
        title: '购物车',
        path: '/cart',
        models: () => [import('./models/cart/cart-model')],
        component: () => import('./routes/cart/cart-route'),
    },
    {
        title: '订单确认',
        path: '/order/create', // 订单确认
        component: () => import('./routes/order/order-create-route'),
    },
    {
        title: '支付结果页',
        path: '/order/pay/result/:id?', //支付结果页
        component: () => import('./routes/order/order-pay-result-route'),
    },
    {
        title: '支付页',
        path: '/order/pay/:id?/:callback?',//支付页
        component: () => import('./routes/order/order-pay-route'),
    },
    {
        title: '出库单详情',
        path: '/order/out/:id?',//出库单详情
        component: () => import('./routes/order/order-list-route'),
    },
    {
        title: '订单列表',
        path: '/order/list', // 订单列表
        models: () => [import('./models/order/order-list-model')],
        component: () => import('./routes/order/order-list-route'),
    },
    {
        title: '订单详情',
        path: '/order/detail/:id', // 订单详情
        component: () => import('./routes/order/order-detail-route'),
    },
    {
        title: '订单发货记录',
        path: '/order/deliver/:id', // 订单发货记录
        component: () => import('./routes/order/order-deliver-route'),
    },
    {
        title: '订单统计',
        path: '/order/statistics', // 订单统计
        component: () => import('./routes/order/order-statistics-route'),
    },
    {
        title: '订单统计详情',
        path: '/order/statistics/detail', // 订单统计详情
        component: () => import('./routes/order/order-statistics-detail-route'),
    },
    {
        title: '合并支付',
        path: '/order/batch/pay', // 合并支付
        component: () => import('./routes/order/order-batch-pay'),
    },
    {
        title: '退货单列表',
        path: '/refund/list', // 退货单列表
        component: () => import('./routes/refund/refund-list-route'),
    },
    {
        title: '退货单详情',
        path: '/refund/detail/:id', // 退货单详情
        component: () => import('./routes/refund/refund-detail-route'),
    },
    {
        title: '退货申请选择商品',
        path: '/refund/select', // 退货申请选择商品
        component: () => import('./routes/refund/refund-select-route'),
    },
    {
        title: '退货申请',
        path: '/refund/apply', // 退货申请
        component: () => import('./routes/refund/refund-apply-route'),
    },
    {
        title: '退货申请结果页',
        path: '/refund/result', // 退货申请结果页
        component: () => import('./routes/refund/refund-result-route'),
    },
    {
        title: '个人中心',
        path: '/user', // 个人中心
        models: () => [import('./models/search/exclusive-goods-model')],
        component: () => import('./routes/user/user'),
    },
    {
        title: '个人信息',
        path: '/user/info', // 个人信息
        component: () => import('./routes/user/user-info'),
    },
    {
        title: '地址列表',
        path: '/address/list', // 地址列表
        component: () => import('./routes/user/user-address'),
    },
    {
        title: '我的收藏',
        path: '/collect', // 我的收藏
        component: () => import('./routes/user/my-collect'),
    },
    {
        title: '常用清单',
        path: '/keep', // 常用清单
        component: () => import('./routes/user/keep-list'),
    },
    {
        title: '浏览记录',
        path: '/footprint', // 浏览记录
        component: () => import('./routes/user/footprint'),
    },
    {
        title: '订单消息',
        path: '/order/message', // 订单消息
        component: () => import('./routes/user/order-message'),
    },
    {
        title: '在线客服(商品咨询)',
        path: '/service', // 在线客服(商品咨询)
        component: () => import('./routes/user/service'),
    },
    {
        title: '联系我们',
        path: '/supplier', // 联系我们
        component: () => import('./routes/user/supplier'),
    },
    {
        title: '设置',
        path: '/setting', // 设置
        component: () => import('./routes/user/setting'),
    },
    {
        title: '账号注销',
        path: '/shop/userLogOff', // 账号注销
        component: () => import('./routes/user/user-log-off'),
    },
    {
        title: '分享设置',
        path: '/share/setting', // 分享设置
        component: () => import('./routes/user/share-setting'),
    },
    {
        title: '公告列表',
        path: '/notice/list', // 公告列表
        component: () => import('./routes/user/notice-list'),
    },
    {
        title: '公告詳情',
        path: '/notice/detail/:id', // 公告詳情
        component: () => import('./routes/user/notice-detail'),
    },
    {
        title: '账户明细',
        path: '/deposit/detail', // 账户明细
        component: () => import('./routes/user/deposit/deposit-detail'),
    },
    {
        title: '授信管理',
        path: '/credit/quota', // 授信管理
        component: () => import('./routes/user/creditQuota/credit-quota'),
    },
    {
        title: '余额充值',
        path: '/deposit/recharge', // 余额充值
        component: () => import('./routes/user/deposit/deposit-recharge'),
    },
    {
        title: '余额充值结果页',
        path: '/deposit/recharge/result', // 余额充值结果页
        component: () => import('./routes/user/deposit/deposit-recharge-result'),
    },
    {
        title: '员工列表',
        path: '/client/account/list', // 员工列表
        component: () => import('./routes/user/clientAccount/list'),
    },
    {
        title: '员工编辑',
        path: '/client/account/edit', // 员工编辑
        component: () => import('./routes/user/clientAccount/edit'),
    },
    {
        title: '员工详情',
        path: '/client/account/detail', // 员工详情
        component: () => import('./routes/user/clientAccount/detail'),
    },
    {
        title: '员工重置密码',
        path: '/client/account/reset/password', // 员工重置密码
        component: () => import('./routes/user/clientAccount/reset-password'),
    },
    {
        title: '立即询价',
        path: '/enquiry', // 立即询价
        models: () => [import('./models/enquiry/enquiry-model')],
        component: () => import('./routes/enquiry/enquiry'),
    },
    {
        title: '车架号\\行驶证行信息',
        path: '/enquiry/scan/detail', // 车架号\行驶证行信息
        models: () => [import('./models/enquiry/enquiry-model')],
        component: () => import('./routes/enquiry/enquiry-scan-detail'),
    },
    {
        title: '询价单添加配件',
        path: '/enquiry/list', // 询价单添加配件
        models: () => [import('./models/enquiry/enquiry-model')],
        component: () => import('./routes/enquiry/enquiry-list'),
    },
    {
        title: '确认询价单',
        path: '/enquiry/creation', // 确认询价单
        models: () => [import('./models/enquiry/enquiry-model')],
        component: () => import('./routes/enquiry/enquiry-creation'),
    },
    {
        title: '询价单详情',
        path: '/enquiry/detail/:id', // 询价单详情
        models: () => [import('./models/enquiry/enquiry-model')],
        component: () => import('./routes/enquiry/enquiry-detail'),
    },

    {
        title: '从结构图添加配件',
        path: '/structure/chart/list', // 从结构图添加配件
        models: () => [import('./models/enquiry/enquiry-model')],
        component: () => import('./routes/enquiry/structure-chart-list'),
    },
    {
        title: '从结构图添加配件',
        path: '/structure/chart/select', // 从结构图添加配件
        models: () => [import('./models/enquiry/enquiry-model')],
        component: () => import('./routes/enquiry/structure-chart-select'),
    },
    {
        title: '车辆配置详情',
        path: '/car/config', // 车辆配置详情
        component: () => import('./routes/enquiry/car-config'),
    },

    {
        title: 'EPC详情',
        path: '/epc/detail/:oe?', // EPC详情
        component: () => import('./routes/enquiry/EPC-detail'),
    },
    {
        title: '询价单列表',
        path: '/inquiry/list', // 询价单列表
        models: () => [import('./models/enquiry/enquiry-model')],
        component: () => import('./routes/enquiry/inquiry-list'),
    },
    {
        title: '求购单列表',
        path: '/buying/list', // 求购单列表
        models: () => [import('./models/enquiry/enquiry-model')],
        component: () => import('./routes/enquiry/buying-list'),
    },
    {
        title: '扫码页',
        path: '/scan/vin', // 扫码页
        component: () => import('./routes/scan/vin-route'),
    },
    {
        title: '收货地址',
        path: '/address/list', // 收货地址
        component: () => import('./routes/user/user-address'),
    },
    {
        title: '我的积分',
        path: '/integral', // 我的积分
        component: () => import('./routes/integral/integral'),
    },

    {
        title: '积分商品列表',
        path: '/integral/list', // 积分商品列表
        component: () => import('./routes/integral/integral-list'),
    },
    {
        title: '积分商品详情',
        path: '/integral/detail/:id', // 积分商品详情
        component: () => import('./routes/integral/integral-detail'),
    },
    {
        title: '积分订单创建',
        path: '/integral/order/create', // 积分订单创建
        component: () => import('./routes/integral/integral-order-create'),
    },

    {
        title: '积分明细',
        path: '/integral/flow', // 积分明细
        component: () => import('./routes/integral/integral-flow'),
    },
    {
        title: '赚积分',
        path: '/integral/rule', // 赚积分
        component: () => import('./routes/integral/integral-rule'),
    },
    {
        title: '积分订单列表',
        path: '/integral/order/list', // 积分订单列表
        component: () => import('./routes/integral/integral-order'),
    },
    {
        title: '积分订单详情',
        path: '/integral/order/detail/:id', // 积分订单详情
        component: () => import('./routes/integral/integral-order-detail'),
    },
    {
        title: '签到',
        path: '/integral/sign', // 签到
        component: () => import('./routes/integral/integral-sign'),
    },
    {
        title: '汽车保有量车型选择',
        path: '/car-parc/model', // 汽车保有量车型选择
        component: () => import('./routes/car-parc/car-parc-model'),
    },
    {
        title: '汽车保有量',
        path: '/car-parc/list', // 汽车保有量
        component: () => import('./routes/car-parc/car-parc-list'),
    },
    {
        title: '优惠券列表',
        path: '/coupon/list', // 优惠券列表
        component: () => import('./routes/user/coupon-list'),
    },
    {
        title: '领券中心',
        path: '/coupon/center', // 领券中心
        component: () => import('./routes/user/coupon-center'),
    },
    {
        title: '快速购买保养件',
        path: '/quickly/buy', // 快速购买保养件
        component: () => import('./routes/enquiry/quickly-buy'),
    },
    {
        title: '快速购买保养件',
        path: '/quickly/share', // 快速购买保养件
        component: () => import('./routes/enquiry/quickly-share'),
    },
    {
        title: '改价分享',
        path: '/quickly/changePrice',
        component: () => import('./routes/enquiry/quickly-change-price'),
    },
    {
        title: '分享报价',
        path: '/quickly/changePriceShare',
        component: () => import('./routes/enquiry/quickly-change-price-share'),
    },
    {
        title: '快速下单',
        path: '/quickly/order/:id', // 分享结果页
        component: () => import('./routes/enquiry/quickly-order'),
    },
    {
        title: '从标准名称分组添加配件',
        path: '/group/list', // 分享结果页
        component: () => import('./routes/enquiry/group-list'),
    },
    {
        title: '通讯列表',
        path: '/im/list', // 通讯列表
        component: () => import('./routes/instant-messaging/list/im-list'),
    },
    {
        title: '通讯列表',
        path: '/im/chat', // 通讯列表
        component: () => import('./routes/instant-messaging/chat'),
    },
    {
        title: '找人代付',
        path: '/order/pay/another/detail/:id', // 找人代付
        // models: () => [import('./models/order/order-detail-model')],
        component: () => import('./routes/order/order-pay-another-detail-route'),
    },
    {
        title: '找人代付',
        path: '/order/another/pay/:id?', // 找人代付
        // models: () => [import('./models/order/order-detail-model')],
        component: () => import('./routes/order/order-another-pay-route'),

    },
    {
        title: '采购中',
        path: '/purchase/list',
        component: () => import('./routes/subsidiary-purchase/purchase/index'),
    },
    {
        title: '待入库',
        path: '/in/stock/list',
        component: () => import('./routes/subsidiary-purchase/in-house/index'),
    },
    {
        title: '待入库',
        path: '/in/stock/apply/:id',
        component: () => import('./routes/subsidiary-purchase/in-house-apply/index'),
    },
    {
        title: '我的库存',
        path: '/stock/list',
        component: () => import('./routes/subsidiary-purchase/stock-list/index'),
    },
    {
        title: '开通会员',
        path: '/member/open',
        models: () => [import('./models/search/exclusive-goods-model')],
        component:()=>import('./routes/user/userMember/member')
    },
    {
        title: '会员开通支付成功',
        path: '/member/paySuccessful/:payPrice',
        component:()=>import('./routes/user/userMember/pay-successful/index')
    },
    {
        title: "会员专享",
        path: '/member/exclusive',
        models: () => [import('./models/search/exclusive-goods-model')],
        component:()=>import('./routes/user/userMember/memberExclusive/index')
    },
    {
        title: "金卡专享价",
        path: '/more/exclusiveGoods',
        models: () => [import('./models/search/exclusive-goods-model')],
        component:()=>import('./routes/user/userMember/exclusiveGoods/index')
    },
    {
        title: '批量询价',
        titleHide: true,
        path: '/quickly/batch',
        component: () => import('./routes/enquiry/enquiry-batch'),
    },
];
export default routers;

